import JWT from "jsonwebtoken";

export const verify = token => {
  return new Promise((resolve, reject) => {
    if (!token || token === "") {
      reject("Token empty");
      return;
    }
    let decoded = JWT.decode(token);
    if (decoded.exp > Date.now()) {
      reject({ message: "Session expired.", status: 408 });
      return;
    }
    resolve(decoded);
  });
};
