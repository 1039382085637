<template>
  <div class="ga_dropdown" :class="[pclass, 'drop' + popupDirection]" v-clickaway="closeDropdown">
    <button
      type="button"
      class="ga_btn ga_dropdown_toggle"
      :class="[bclass, { 'active': showDropdown }]"
      ref="inputBounder"
      @click="toggleDropdown"
    >
      <slot name="button">Toggle Dropdown</slot>
      <svg v-if="!hideArrow" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.44103 4.06603C1.48167 4.02529 1.52995 3.99296 1.5831 3.97091C1.63625 3.94885 1.69324 3.9375 1.75078 3.9375C1.80833 3.9375 1.86531 3.94885 1.91846 3.97091C1.97161 3.99296 2.01989 4.02529 2.06053 4.06603L7.00078 9.00716L11.941 4.06603C11.9817 4.02535 12.03 3.99309 12.0831 3.97107C12.1363 3.94906 12.1933 3.93773 12.2508 3.93773C12.3083 3.93773 12.3653 3.94906 12.4184 3.97107C12.4716 3.99309 12.5199 4.02535 12.5605 4.06603C12.6012 4.10671 12.6335 4.155 12.6555 4.20815C12.6775 4.26129 12.6888 4.31826 12.6888 4.37578C12.6888 4.43331 12.6775 4.49027 12.6555 4.54342C12.6335 4.59656 12.6012 4.64486 12.5605 4.68553L7.31053 9.93553C7.26989 9.97628 7.22161 10.0086 7.16846 10.0307C7.11531 10.0527 7.05833 10.0641 7.00078 10.0641C6.94324 10.0641 6.88625 10.0527 6.8331 10.0307C6.77995 10.0086 6.73167 9.97628 6.69103 9.93553L1.44103 4.68553C1.40029 4.64489 1.36796 4.59661 1.34591 4.54346C1.32385 4.49031 1.3125 4.43333 1.3125 4.37578C1.3125 4.31824 1.32385 4.26125 1.34591 4.2081C1.36796 4.15495 1.40029 4.10667 1.44103 4.06603Z" fill="#7F7F8D"/>
      </svg>
    </button>
    <transition name="fadeIn">
      <div class="ga_dropdown_list" :class="lclass" ref="dropContain" v-if="showDropdown">
        <slot>Dropdown items</slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    pclass: String,
    bclass: String,
    lclass: String,
    hideArrow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDropdown: false,
      inVP: true,
      elHeight: 0
    };
  },
  computed: {
    popupDirection() {
      if (this.inVP) {
        return "down";
      }
      return "up";
    }
  },
  methods: {
    toggleDropdown() {
      if (this.showDropdown) {
        this.showDropdown = false;
      } else {
        this.showDropdown = true;
      }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    autoPosition() {
      if (!this.$refs) return;
      if (!this.$refs.inputBounder) return;
      if (this.$refs.dropContain)
        this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
      let viewportTop = window.pageYOffset,
        viewportBottom =
          window.pageYOffset +
          window.document.documentElement.clientHeight -
          80,
        rect = this.$refs.inputBounder.getBoundingClientRect(),
        elementTop = rect.top + viewportTop,
        elementBottom = elementTop + rect.height;
      // console.log({
      //   elementBottom,
      //   viewportBottom
      // });
      if (elementBottom + this.elHeight <= viewportBottom) this.inVP = true;
      else this.inVP = false;
    }
  },
  created() {
    this.$emit("created", this);
    window.addEventListener("scroll", this.autoPosition);
    window.addEventListener("mousewheel", this.autoPosition);
    window.addEventListener("ps-scroll-y", this.autoPosition);
    window.addEventListener("resize", this.autoPosition);
    this.toggleDropdown();
    this.autoPosition();
    this.toggleDropdown();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.autoPosition);
    window.removeEventListener("mousewheel", this.autoPosition);
    window.removeEventListener("ps-scroll-y", this.autoPosition);
    window.removeEventListener("resize", this.autoPosition);
  },
  watch: {
    showDropdown(newV) {
      if (this.$refs && this.$refs.dropContain)
        this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
    },
    elHeight(newV) {
      this.autoPosition();
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  &-enter-active {
    animation-name: fadeIn;
    animation-duration: 0.2s;
  }

  &-leave-active {
    animation-name: fadeIn;
    animation-duration: 0.2s;
    animation-direction: reverse;
  }
}

.ga_dropdown {
  position: relative;
  display: flex;
  // flex-direction: row;
  margin-left: auto;
  align-items: center;
  align-self: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #627381;

  &_toggle {
    // border: none;
    // outline: none;
    align-self: center;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    flex: 1;

    &:focus {
      // border: inherit;
    }

    svg {
      // margin-left: 10px;
      margin-left: auto;
      transition: all 0.3s ease;
      // color: white; 
    }

    &.active {

      // color: #ff4757;
      svg {
        transform: rotate(180deg);

        path {
          // fill: #ff4757;
        }
      }
    }
  }

  &_list {
    $arrowHeight: 10px;
    $arrowWidth: 10px;

    position: absolute;
    display: flex;
    flex-flow: column;
    padding: 10px;
    // top: calc(100% + #{$arrowHeight} + 2px);
    top: 100%;
    // width: calc(100% + 26px);
    width: calc(100%);
    right: 0px;
    // text-align: right;
    z-index: 50;
    transform: translate(0, 0);

    border-radius: 5px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.01);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    // transform: translateY(50%)

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   right: 20px;
    //   border-left: $arrowWidth solid transparent;
    //   border-right: $arrowWidth solid transparent;
    //   border-bottom: $arrowHeight solid white;
    //   box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    //   /* border-top: 5px solid black; */
    // }
  }
}
</style>
<style lang="scss">
.ga_dropdown_item {
  padding: 9px 13px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #627381;

  &:hover {
    color: #5f6ec7;
    background-color: #f3f5f7;
    cursor: pointer;
  }
}

.aff-error {
  .ga_dropdown_toggle {
    border: 1px solid #ea3335 !important;
  }
}
</style>
