<template>
  <!-- <iframe src="http://docs.google.com/gview?url=https://public-test-affiliate.s3.amazonaws.com/agreement.pdf&embedded=true" style="width:100%; height:calc(80vh);" frameborder="0"></iframe> -->
  <iframe src="https://share-docs.clickup.com/3807076/d/h/3m5v4-47845/a766308d95dde41" style="width:100%; height:calc(80vh);" frameborder="0" scrolling="auto" onload="scroll(10,0);"></iframe>
  
    <!-- <h2>Affiliate Agreement, Terms and Conditions and Privacy Policy</h2>
    <main class="scrollBody">
      <section>
        <h3>Important</h3>
        <ol>
          <li>The affiliate commission is calculated before 20% deduction of Shopify commission fee from Gempages paid plans.</li>
          <li>We have the 30-Day Money Back Guarantee policy. When the merchant requests a refund during this period, if the refund proceeds the affiliate commission will not be counted.</li>
          <li>Please use "ref=nofollow" link. Referral link and commission will be counted only with this link's type.</li>
          <li>Your affiliate referral link includes a 30-day tracking cookie. When visitors click on your referral link, a cookie will be put on their computer to keep track of whether they purchase our paid plans via your affiliate link. However, in case they purchase our paid plans by another computer. The affiliate commission will not be counted.</li>
        </ol>
      </section>
      <section>
        <h3>Prohibited Activities.</h3>
        <ol>
          <li>You are not allowed to earn commissions on your own purchases</li>
          <li>Promote using an unofficial coupon, or rebate offer.</li>
          <li>Use your affiliate link on a coupon, promo, or deal site.</li>
          <li>Use "Gempages" in your domain/personal branding.</li>
          <li>Alter our logos or creatives without our consent.</li>
          <li>Falsely misrepresent Gempages in any way.</li>
          <li>Spam sites with your affiliate link.</li>
          <li>Assert that you work for Gempages.</li>
          <li>Buying traffic.</li>
          <li>Using deceptive practices, encouraging users to click banners or use of any automated means to create traffic.</li>
          <li>Offering others a part of your affiliate commission</li>
        </ol>
        <p>We will reverse your commissions and terminate your participation in the program if you break our rules.</p>
      </section>
      <section>
        <h3>Our License To You.</h3>
        <article>
          <p>We reserve all our intellectual property rights; and we provide our affiliates a limited, temporary, non-exclusive, non-transferrable, non-sublicensable license to use our trademark, brand name and promotional materials, and such license is limited only to the use in promoting our products and services with our permitted banners. This means that you cannot use our intellectual property with another brand name or as a bundled project. It also means that you cannot allow others to use the content we licensed to you.</p>
          <p>You may only use the permitted banners and promotional material that we provide you as a part of our gallery.</p>
          <p>All promotional materials must include a link to our website.</p>
        </article>
      </section>
      <section>
        <h3>Payment.</h3>
        <article>
          <p>You shall bear all taxes and expenses relating to the commission we pay you, including any fees which may be incurred by your bank.</p>
        </article>
      </section>
      <section>
        <h3>Termination.</h3>
        <article>
          <p>We reserve the right to terminate your participation in Gempages affiliate program (i.e. your membership in the Program), at any time, by providing you with notice of such termination (to the email account with which you applied to the Program), and with immediate effect, at Gempages’s sole discretion. When we terminate our relationship, you must cease using all of our intellectual property.</p>
        </article>
      </section>
      <section>
        <h3>Indemnification.</h3>
        <article>
          <p>You will hold us harmless and indemnify us from all and any claims, damages, lawsuits, actions, complaints or any other cost we may pay a third party in relation to its claim based on your services under this agreement. Meaning, that if your banners infringe copyright, then if we are sued by the copyright holder, you will be required to pay him.</p>
        </article>
      </section>

      <section>
        <h3>Termination of Your Participation.</h3>
        <article>
          <p>If we terminate your participation due to prohibited activities, we will not pay you any fees.</p>
        </article>
      </section>

      <slot name="footer"></slot>
    </main> -->
</template>

<script>
export default {
  name: "TermOfService"
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/media_queries.scss";
h2 {
  margin-bottom: 1.1rem;
}
section {
  $indent: 2.5rem;
  padding: 10px 0;
  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
    color: inherit;
    font-weight: 400;
    &:first-of-type {
      text-indent: $indent;
    }
  }
  ol {
    margin-left: $indent;
  }
}

@include phone {
  h2 {
    font-size: 16px;
  }
  section {
    h3 {
      font-size: 15px;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}
</style>