import * as Sentry from "@sentry/vue";

export const sentrySetIdentification = (shop) => {
  Sentry.setUser({
    id: String(shop.email),
  });
};

export const sentryCaptureException = (funcName, message, data, options) => {
  Sentry.withScope((scope) => {
    scope.setLevel(options?.level ?? "log");
    if (options?.tag) {
      scope.setTag(options?.tag.key, options?.tag.value);
    }
    scope.setExtra("function", funcName);
    scope.setExtra("data", btoa(JSON.stringify(data)));
    Sentry.captureMessage(`${funcName}: ${message}`);
  });
};
