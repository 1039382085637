import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import camelCase from "@/plugins/lodash/camelCase.js";
import upperFirst from "@/plugins/lodash/upperFirst.js";
import PortalVue from "portal-vue";
import i18n from "./i18n";
import * as Sentry from "@sentry/vue";
// import DisableAutocomplete from 'vue-disable-autocomplete';

// Vue.use(DisableAutocomplete);

// import "roboto-fontface/css/roboto/roboto-fontface.css";

Vue.config.productionTip = false;

const requireComponent = require.context(
  // The relative path of the components folder
  "./components/base/",
  // Whether or not to look in oepnsubfolders
  false,
  // /Base[A-Z]\w+\.(vue|js)$/
  /[\w-]+\.vue$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.directive("clickaway", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    window.addEventListener("click", el.clickOutsideEvent);
    window.addEventListener("touchstart", el.clickOutsideEvent);
  },
  unbind: function (el) {
    window.removeEventListener("click", el.clickOutsideEvent);
    window.removeEventListener("touchstart", el.clickOutsideEvent);
  },
});

Vue.directive("closable", {
  bind(el, binding, vnode) {
    let handleOutsideClick = (e) => {
      e.stopPropagation();
      let { handler, exclude } = binding.value;
      if (!handler) handler = binding.expression;
      let clickedOnExcludedEl = false;
      if (exclude) {
        exclude.forEach((refName) => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName];
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        });
      }
      // console.log({
      //   context:vnode.context,
      //   handler,
      //   binding
      // })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },

  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

Vue.directive("validate-website", {
  bind(el, binding, vnode) {
    el.addEventListener("keyup", function (ele) {
      const isEnable = ele.target.dataset.enable;
      if (isEnable != "true") {
        return;
      }
      const regex =
        /^(?:(?:https?|ftps?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
      const website = ele.target.value;
      const isValid = regex.test(website);
      let error = "";
      if (website.length === 0) {
        error = "Website or Social Link is required";
      } else if (!isValid) {
        error = "Invalid link";
      }
      vnode.context[binding.expression](error);
      return;
    });
  },
});
Vue.directive("validate-email", {
  bind(el, binding, vnode) {
    el.addEventListener("keyup", function (ele) {
      const isEnable = ele.target.dataset.enable;
      if (isEnable != "true") {
        return;
      }
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const email = ele.target.value && ele.target.value.trim();
      const isValid = regex.test(email);
      let error = "";
      if (email.length === 0) {
        error = "Email is required";
      } else if (!isValid) {
        error = "Invalid email";
      }
      vnode.context[binding.expression](error);
      return;
    });
  },
});
Vue.directive("validate-password", {
  bind(el, binding, vnode) {
    el.addEventListener("keyup", function (ele) {
      const isEnable = ele.target.dataset.enable;
      if (isEnable != "true") {
        return;
      }
      const password = ele.target.value;
      const isValid = password.length >= 8;
      let label = ele.target.dataset.label
        ? ele.target.dataset.label
        : "Password";
      let error = "";
      if (password.length === 0) {
        error = `${label} is required`;
      } else if (!isValid) {
        error = "At least 8 characters";
      }
      // Using for confirm password
      const dataPassword = ele.target.dataset.password;
      if (dataPassword && password != dataPassword) {
        error = `${label} don't match`;
      }
      vnode.context[binding.expression](error);
      return;
    });
  },
});
Vue.directive("validate-required", {
  bind(el, bindingR, nodeR) {
    el.addEventListener("keyup", function (ele) {
      const isEnable = ele.target.dataset.enable;
      if (isEnable != "true") {
        return;
      }
      const value = ele.target.value;
      const label = ele.target.dataset.label;
      let error = "";
      if (value.length === 0) {
        error = `${label} is required`;
        ele.target.classList.add("aff-error");
      }
      let errorDiv = ele.target.nextSibling;
      if (error.length === 0) {
        ele.target.classList.remove("aff-error");
        errorDiv && errorDiv.remove();
      } else {
        if (
          !errorDiv ||
          (errorDiv.classList && !errorDiv.classList.contains("aff-error"))
        ) {
          errorDiv = document.createElement("div");
          errorDiv.classList.add("aff-group-help-text", "aff-error");
          ele.target.parentNode.insertBefore(errorDiv, ele.target.nextSibling);
        }
        errorDiv.textContent = error;
      }
      if (nodeR && bindingR && bindingR.expression) {
        nodeR.context[bindingR.expression](error);
      }
      return;
    });
  },
});

Vue.use(PortalVue);

Sentry.init({
  Vue,
  dsn: "https://f39ef6cc1526b54100ebef3e6ab0dcba@sentry.gemcommerce.xyz/34",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  sourcemaps: {
    ignore: ["node_modules"],
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
