import api from "./index";

export default {
  buildQueryParams(from, to) {
    const queryParams = [];
    if (from) {
      queryParams.push(`from=${from}`);
    }
    if (to) {
      queryParams.push(`to=${to}`);
    }
    return queryParams.join('&');
  },
  
  getPending(from = undefined, to = undefined) {
    const query = this.buildQueryParams(from, to);
    return api().get(`/api/commission/pending?${query}`);
  },
  
  getEarned(from = undefined, to = undefined) {
    const query = this.buildQueryParams(from, to);
    return api().get(`/api/commission/earned?${query}`);
  },
  
  getCustomers(from = undefined, to = undefined) {
    const query = this.buildQueryParams(from, to);
    return api().get(`/api/commission/customers?${query}`);
  },
  
  getSummary(from = undefined, to = undefined) {
    const query = this.buildQueryParams(from, to);
    return api().get(`/api/commission/summary?${query}`);
  },
  
  getCurrentCommission() {
    return api().get(`/api/commission/current`)
  },
};
