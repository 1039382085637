import api from "./index";
import { catcher } from "./handleError";

export default {
  get(id) {
    return api().get(`/api/user/${id}`);
  },
  login(credential) {
    return api().post(`/api/user/login`, credential);
  },
  logout(id) {
    return api().get(`/api/user/logout?id=${id}`).catch(catcher);
  },
  register(credential) {
    return api().post(`/api/user`, credential);
  },
  changePassword(id, credential) {
    return api().put(`/api/user/${id}/password`, credential).catch(catcher);
  },
  changePayment(id, data) {
    return api().put(`/api/user/${id}/payment`, data).catch(catcher);
  },
  resetPassword(credential) {
    return api().post(`/api/user/password/reset`, credential);
  },
  checkToken(data) {
    return api().post(`/api/user/check-token`, data);
  },
  forceChangePassword(data) {
    return api().post(`/api/user/change/password`, data);
  },
  updateInfo(id, info) {
    return api().put(`/api/user/${id}`, info).catch(catcher);
  },
};
