<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>

<style lang="scss">
@import "@/assets/sass/style.scss";
</style>