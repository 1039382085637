import Cookie from "@/utils/cookie.js";
import { verify } from "@/utils/jwt.js";
import auth from "@/api/auth.js";
import commissionAPI from "@/api/commission.js";

const TYPES = {
  UPDATE_INFO: "USER_UPDATE_INFO",
  SIGN_IN: "USER_SIGN_IN",
  SIGN_OUT: "USER_SIGN_OUT",
  FETCH_WALLET: "USER_FETCH_WALLET",
  RESET: "USER_RESET",
  TIER_INFO: "TIER_INFO",
};
export const TIER_1 = 1;
export const TIER_2 = 2;
export const COMMISSION_TIER_1 = 25;
export const COMMISSION_TIER_2 = 50;

export const COMMISSION_CONDITIONS = {
  EARNED: 500,
  TRAFFIC: 500,
  PAID_CUSTOMER: 10,
};

const defaultState = () => {
  return {
    info: null,
    wallet: {
      status: "",
      balance: 0,
      commission: 0,
      refCount: 0,
      state: {
        loading: false,
        fetched: false,
      },
      timeUpdateTier: "",
      nextCheckTime: "",
      tierComparison: {},
    },
    commissionShare: null,
    tier: null,
  };
};

export default {
  state: defaultState(),
  mutations: {
    [TYPES.UPDATE_INFO](state, info) {
      state.info = info;
    },
    [TYPES.SIGN_IN](state, credential) {
      state.info = credential.info;
      Cookie.set(Cookie.key, credential.token);
    },
    [TYPES.SIGN_OUT](state) {
      Cookie.remove(Cookie.key);
      state.info = null;
    },
    [TYPES.FETCH_WALLET](state, wallet) {
      Object.keys(wallet).forEach((key) => {
        state.wallet[key] = wallet[key];
      });
    },
    [TYPES.RESET](state) {
      let initial = defaultState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    },
    [TYPES.TIER_INFO](state, data) {
      state.tier = data.tier;
      state.commissionShare = data.tier == TIER_1 ? COMMISSION_TIER_1 : COMMISSION_TIER_2;
    },
  },
  actions: {
    userSignin(store, { token, onSuccess }) {
      verify(token)
        .then((decoded) => {
          let id = decoded.id;

          // Loi api tra ve van la 200
          store.commit(TYPES.SIGN_IN, { info: decoded, token });
          auth
            .get(id)
            .then((res) => {
              if (res.data.status) {
                store.commit(TYPES.UPDATE_INFO, res.data.user);
                store.commit(TYPES.TIER_INFO, {
                  tier: res.data.user.tier,
                });
                if (onSuccess) onSuccess();
              } else {
                store.commit("OpenError", {
                  message: res.data.message,
                });
              }
            })
            .catch((err) => {
              console.error({ err });
              store.commit("OpenError", {
                message: err,
              });
            });
        })
        .catch((err) => {
          console.error(err);
          store.commit("OpenError", { message: "Token Error" });
        });
    },
    userFetchWallet(store) {
      let wallet = store.state.wallet;
      if (!wallet.loading) {
        wallet.loading = true;
        commissionAPI
          .getCurrentCommission()
          .then((res) => {
            wallet.state.loading = false;

            if (res.data && res.data.status) {
              store.commit(TYPES.FETCH_WALLET, res.data);
              // store.commit(TYPES.TIER_INFO, {
              //   tier: res.data.user.tier,
              // });
              wallet.state.fetched = true;
            } else {
              store.commit("OpenError", { message: res.data.message });
            }
          })
          .catch((err) => {
            wallet.state.loading = false;
            console.error({ err });
            store.commit("OpenError", {
              message:
                (err.response &&
                  err.response.data &&
                  err.response.data.message) ||
                "Can't get current commission",
            });
          });
      }
    },
  },
};
