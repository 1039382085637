let Intercom = (function () {
  const defaultIntercomSettings = {
    app_id: "z11bwnw3",
    custom_launcher_selector: "#gp-intercom",
    alignment: "right",
    horizontal_padding: 20,
    vertical_padding: 10,
    action_color: "#333",
    utm_term: "gempages_affiliate",
    api_base: "https://z11bwnw3.intercom-messenger.com",
  }
  window.intercomSettings = defaultIntercomSettings;

  function _setIntercomUser(user) {

    // Update data layer for google tags manager
    window.dataLayer = window.dataLayer || [];
    const firstName = user.firstName
    const lastName = user.lastName
    const email = user.email
    const createdAt = user.createdAt
    const userID = user.id
    const isAgency = user.isAgency
    const userHash = user.userHash

    window.intercomSettings = Object.assign(defaultIntercomSettings, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      created_at: createdAt,
      user_id: userID,
      is_agency: isAgency,
      user_hash: userHash
    });

    (function () {
      var w = window;
      var ic = w.Intercom;
      var urlString = window.location.href
      var url = new URL(urlString);
      var chatParams = url.searchParams.get("chat");
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", intercomSettings);
        if (chatParams && chatParams == "intercom") {
          window.Intercom("show");
        }
        window.___intercomStatus = "success";
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.setAttribute("data-cookieyes", "cookieyes-necessary");
          s.async = true;
          window.___intercomStatus = "running";
          s.onerror = function () {
            console.log("INTERCOM ERROR");
            window.dispatchEvent(new Event("INTERCOM_ERROR"));
            window.___intercomStatus = "error";
          };
          s.onload = function () {
            window.___intercomStatus = "success";
          };
          s.src = "https://widget.intercom.io/widget/z11bwnw3";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
          if (chatParams && chatParams == "intercom") {
            window.Intercom("show");
          }
        };
        l();
      }
    })();
  }
  return {
    setIntercomUser: _setIntercomUser
  };

})()

export default Intercom;
