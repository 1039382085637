<template>
  <div class="ga_m-term" v-if="term.show" @click="closeBody">
    <div class="ga_m-term-body">
      <div class="ga_m-term-close" @click="CloseTerm">&times;</div>
      <TermOfService>
        <template v-slot:footer>
        </template>
      </TermOfService>
          <footer>
            <button class="ga_btn ga_btn-outline-secondary" @click="decline">Decline</button>
            <button
              class="ga_btn ga_btn-primary"
              @click="agree"
            >I agree to Affiliate Agreement</button>
          </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TermOfService from "@/components/essenstials/TermOfService.vue";
export default {
  name: "ModalTerms",
  components: {
    TermOfService
  },
  computed: mapState({
    term: state => state.overlay.term
  }),
  methods: {
    ...mapMutations({
      OpenTerm: "OpenTerm",
      CloseTerm: "CloseTerm"
    }),
    closeBody() {
      let $target = event.target;
      if (!$target.closest(".ga_m-term-body")) {
        this.CloseTerm();
      }
    },
    agree() {
      this.$emit("agree");
      this.CloseTerm();
    },
    decline() {
      this.$emit("decline");
      this.CloseTerm();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/media_queries.scss";
.ga_m-term {
  position: fixed;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ga_m-term-body {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 800px;
  color: #333;

  .ga_m-term-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    transition: color 0.2s ease;
    &:hover {
      color: #e5332a;
    }
  }

  ::v-deep {
    .scrollBody {
      max-height: 65vh;
      overflow: auto;
    }

    .ga_btn {
      font-size: 1em;
    }
  }
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
  button {
    margin: 10px;
  }
}

@include tablet {
  .ga_m-term-body {
    min-width: 550px;
    max-width: 100vw;
    max-height: 100vh;
    padding: 20px 30px;
  }
}

@include phone {
  .ga_m-term-body {
    min-width: unset;
    padding: 10px 15px;
  }
  footer {
    margin-top: 15px;
  }
  .ga_btn {
    padding: 0.2rem 0.4rem;
  }
}
</style>
