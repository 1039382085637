import Vue from "vue";
import store from "@/store/index.js";
import Router from "vue-router";

import RouterContainer from "@/views/RouterContainer.vue";

import Default from "@/layouts/Default.vue";
// import Page404 from "@/pages/404.vue";
// import SignIn from "@/pages/auth/SignIn.vue";
// import SignUp from "@/pages/auth/SignUp.vue";
// import SignUpSuccess from "@/pages/auth/SignUpSuccess.vue";
// import Forgot from "@/pages/auth/Forgot.vue";
import SignOut from "@/pages/auth/SignOut.vue";
import AuthenPage from "@/pages/auth/Index.vue";

import Cookie from "@/utils/cookie.js";
import { verify } from "@/utils/jwt.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: AuthenPage,
      children: [
        {
          path: "/signin",
          name: "SignIn",
          component: () => import("@/pages/auth/SignIn.vue"),
          props: {
            tab: "sign-in",
          },
        },
        {
          path: "/forgot",
          name: "ForgotPassword",
          component: () => import("@/pages/auth/Forgot.vue"),
          props: {
            tab: "forgot-password",
          },
        },
        {
          path: "/reset-announcement",
          name: "ResetAnnouncement",
          component: () => import("@/pages/auth/ResetAnnouncement.vue"),
          props: {
            tab: "reset-announcement",
          },
        },
        {
          path: "/reset/password",
          name: "ResetPassword",
          component: () => import("@/pages/auth/ResetPassword.vue"),
          props: {
            tab: "reset-password",
          },
        },
        {
          path: "/reset/password/success",
          name: "ResetPasswordSuccess",
          component: () => import("@/pages/auth/ResetPasswordSuccess.vue"),
          props: {
            tab: "reset-password-success",
          },
        },
        {
          path: "/token-expire",
          name: "TokenExpire",
          component: () => import("@/pages/auth/TokenExpire.vue"),
          props: {
            tab: "token-expire",
          },
        },
        {
          path: "/signup",
          name: "SignUp",
          component: () => import("@/pages/auth/SignUp.vue"),
          props: {
            tab: "sign-up",
          },
        },
        {
          path: "/signup/done",
          name: "SignUpSuccess",
          component: () => import("@/pages/auth/SignUpSuccess.vue"),
          props: {
            tab: "sign-up",
          },
        },
        {
          path: "/signout",
          name: "SignOut",
          component: SignOut,
        },
      ],
    },
    {
      path: "/",
      component: Default,
      children: [
        {
          path: "",
          redirect: { name: "Dashboard" },
          name: "Root",
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/pages/dashboard/Dashboard.vue"),
        },
        {
          path: "referral",
          component: RouterContainer,
          children: [
            {
              path: "",
              name: "MyReferral",
              component: () => import("@/pages/referrals/MyReferral.vue"),
            },
            {
              path: "shop/:shop",
              name: "ShopInfo",
              component: () => import("@/pages/shop/ShopInfo.vue"),
            },
            {
              path: "link",
              name: "ReferralLink",
              component: () => import("@/pages/referrals/ReferralLink.vue"),
            },
          ],
        },
        {
          path: "payout",
          name: "Payout",
          component: () => import("@/pages/payout/Payout.vue"),
        },
        {
          path: "settings",
          name: "Settings",
          component: () => import("@/pages/account/Settings.vue"),
        },
        {
          path: "term",
          name: "TermPolicy",
          component: () => import("@/pages/term/Index.vue"),
        },
        {
          path: "resources",
          name: "Resources",
          component: () => import("@/pages/resources/Index.vue"),
        },
      ],
      beforeEnter: (to, from, next) => {
        let token = Cookie.get(Cookie.key);
        if (token) {
          verify(token)
            .then((ok) => {
              next(true);
              return;
            })
            .catch((err) => {
              console.error({ err, location: "router" });
              next({ name: "SignIn" });
            });
        } else {
          next({ name: "SignIn" });
        }
      },
    },
    {
      path: "*",
      name: "Fallback",
      component: () => import("@/pages/404.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit("loader/START_LOADING");
  next();
});

router.afterEach((to, from) => {
  store.commit("loader/FINISH_LOADING");
});

export default router;
