<template>
  <main class="aff-auth-section" :class="{ 'aff-auth-section--signup': $route.name == 'SignUp' }">
    <Header></Header>
    <div class="aff-scroll-overlay">
      <div class="aff-container aff-auth">
        <router-view @loading="changeLoading"></router-view>
        <Loading />
        <Error />
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "@/components/Loading.vue";
import Error from "@/components/message/Error.vue";
import { mapMutations } from "vuex";
import Header from "@/components/layout/Header.vue";
export default {
  name: "AuthenPage",
  components: {
    Loading,
    Error,
    Header
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading"
    }),
    changeLoading(bool) {
      console.log("Change to " + bool);
      if (bool) {
        this.OpenLoading();
      } else {
        this.CloseLoading();
      }
    },
    routerHandle() {
      let route = this.$route;
      if (route.name != "SignIn") {
        this.$router.push({name: "SignIn"});
      }
    }
  },
  created() {
    document.title = "Login affiliate - Gempages";
  }
};
</script>
<style lang="scss" scoped>
.aff-auth-section {
  background-color: #FEFFFF;
  min-height: 100vh;
  position: relative;
  background-image: url(@/assets/images/aff-auth-bg.svg);
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &--signup {
    background-image: url(@/assets/images/aff-auth-signup-bg.svg);
  }

  @media screen and (max-width: 1199px) {
    background-image: url(@/assets/images/aff-auth-bg-mb.svg);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 351.95px;
    width: 100%;
    z-index: -1;
    left: 0px;
    opacity: 0.4;
    background-color: transparent;
    background-image: url(@/assets/images/auth-bg.svg);

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.aff-scroll-overlay {
  max-height: calc(100vh - 89px);
  height: calc(100vh - 89px);
  overflow-y: overlay;

  @media screen and (max-width: 375px) {
    height: calc(100vh - 84px);
  }
}

.aff-auth {
  padding: 120px 15px 40px 15px;
  width: 1200px;
  max-width: 100%;
  padding-top: 120px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    padding-top: 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 70px 15px 25px 15px;
    width: 385px;
  }

  @media screen and (max-width: 375px) {
    padding: 70px 15px 70px 15px;
    width: 100%;
  }
}
</style>