<template>
  <Portal to="modals">
    <div class="gt_modal" :key="keyModal" :class="bClass" @click.self="handleCancel">
      <div class="gt_modal-backdrop" @click.prevent="handleCancel" v-if="!noBackdrop"></div>
      <div class="gt_modal-container" :class="cClass">
        <header class="gt_modal-header" :class="hClass" v-if="hasHeader">
          <slot name="header"></slot>
          <div class="gf_ml-auto gt_modal-close" v-if="!hideExit" @click.prevent="handleCancel">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z" fill="#676767"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3536 2.64645C13.5488 2.84171 13.5488 3.15829 13.3536 3.35355L3.35355 13.3536C3.15829 13.5488 2.84171 13.5488 2.64645 13.3536C2.45118 13.1583 2.45118 12.8417 2.64645 12.6464L12.6464 2.64645C12.8417 2.45118 13.1583 2.45118 13.3536 2.64645Z" fill="#676767"/>
            </svg>
          </div>
        </header>
        <header class="gt_modal-container_actions" :class="aClass" v-if="hasActions">
          <slot name="actions"></slot>
        </header>
        <main class="gt_modal-body" :class="mClass" v-if="hasBody">
          <slot></slot>
          <div :key="key" class="gf_ml-auto gt_modal-close" v-if="showExitBody" @click.prevent="handleCancel">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z" fill="#676767"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3536 2.64645C13.5488 2.84171 13.5488 3.15829 13.3536 3.35355L3.35355 13.3536C3.15829 13.5488 2.84171 13.5488 2.64645 13.3536C2.45118 13.1583 2.45118 12.8417 2.64645 12.6464L12.6464 2.64645C12.8417 2.45118 13.1583 2.45118 13.3536 2.64645Z" fill="#676767"/>
            </svg>
          </div>
        </main>
        <footer class="gt_modal-footer" :class="fClass" v-if="hasFooter">
          <slot name="footer">
            <div class="gt_modal-footer-action" v-if="btnPrimaryText">
              <div class="gt_modal-footer-action-btn gt_modal-footer-action-btn--primary" @click="handleButtonPrimary()">{{ btnPrimaryText }}</div>
            </div>
          </slot>
        </footer>
      </div>
    </div>
  </Portal>
</template>

<script>
export default {
  name: "Modal",
  props: {
    aClass: String,
    bClass: String,
    cClass: String,
    hClass: String,
    mClass: String,
    fClass: String,
    noBackdrop: {
      type: Boolean,
      default: false
    },
    hideExit: {
      type: Boolean,
      default: false
    },
    showExitBody: {
      type: Boolean,
      default: false
    },
    btnPrimaryText: {
      type: String,
      default: ""
    },
    keyModal: {
      type: String,
      default: ""
    },
  },
  computed: {
    hasHeader() {
      return !!this.$slots["header"] || !!this.$scopedSlots["header"];
    },
    hasActions() {
      return !!this.$slots["actions"] || !!this.$scopedSlots["actions"];
    },
    hasBody() {
      return !!this.$slots["default"] || !!this.$scopedSlots["default"];
    },
    hasFooter() {
      return !!this.$slots["footer"] || !!this.$scopedSlots["footer"];
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleButtonPrimary() {
      this.$emit("button-primary");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/media_queries.scss";
$baseIndex: 99999999999999;

.gt_modal {
  position: fixed;
  z-index: $baseIndex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--md {
    width: 560px;
    max-width: 80vw;
  }

  &-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    padding: 16px;
    align-items: center;
    display: flex;

    @include phone {
      font-size: 18px;
    }

    &--br-b {
      border-bottom: 1px solid #EDEDED;
    }
  }

  &-body {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 150%;
    color: #000000;
    padding: 0 16px;
  }

  &-footer {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 150%;
    padding: 16px;

    &--br-t {
      border-top: 1px solid #EDEDED;
    }

    &-action {
      display: flex;
      justify-content: end;

      &-btn {
        padding: 0 24px;
        height: 40px;
        font-family: 'Poppins';
        font-style: normal;
        line-height: 150%;
        display: flex;
        align-items: center;
        cursor: pointer;

        &--primary {
          border-radius: 3px;
          background: #3C67FF;
          font-size: 14px;
          text-align: center;
          color: #FFFFFF;

          &:hover {
            background: #365DE5;
          }
        }
      }
    }
  }

  &-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(37, 37, 37, 0.85);
    backdrop-filter: blur(8px);
  }

  &-container {
    background: white;
    display: flex;
    z-index: 10;
    flex-direction: column;
    border-radius: 5px;
    max-width: calc(100% - 30px);
  }

  &-close {
    padding: 8px;
    transition: all 0.3s ease;
    color: #676767;
    margin-left: auto;
    cursor: pointer;
    border-radius: 3px;
    display: flex;

    &:hover {
      background-color: #E5E5E5;
    }
  }
}
</style>

