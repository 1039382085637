import {FETCH} from "@/utils/list.js";
import referralAPI from "@/api/referral.js";
import rejectedShopAPI from "@/api/rejectedShop.js";

const TYPES = {
  FETCH_REFERRAL: "REFERRAL_FETCH_REFERRALS",
  FETCH_REJECTED_SHOP: "REFERRAL_FETCH_REJECTED_SHOP",
  FETCH_ACTIVITY: "REFERRAL_FETCH_ACTIVITIES",
  FETCH_RECENT: "REFERRAL_FETCH_RECENT_ACTIVITIES",
  PAGINATION_REFERRAL: "REFERRAL_UPDATE_PAGINATION_REFERRALS",
  PAGINATION_REJECTED_SHOP: "REFERRAL_UPDATE_PAGINATION_REJECTED_SHOP",
  PAGINATION_ACTIVITY: "REFERRAL_UPDATE_PAGINATION_ACTIVITIES",
  TO_PAGE_REFERRAL: "REFERRAL_TO_PAGE_REFERRALS",
  TO_PAGE_REJECTED_SHOP: "REFERRAL_TO_PAGE_REJECTED_SHOP",
  TO_PAGE_ACTIVITY: "REFERRAL_TO_PAGE_ACTIVITIES",
  RESET: "REFERRAL_RESET"
}
const defaultState = () => {
  return {
    referral: {
      list: [],
      loading: false,
      fetched: false,
      pagination: {
        limit: 10,
        page: 1,
        total: 1
      }
    },
    rejectedShop: {
      list: [],
      loading: false,
      fetched: false,
      pagination: {
        limit: 10,
        page: 1,
        total: 1
      }
    },
    activity: {
      list: [],
      loading: false,
      fetched: false,
      pagination: {
        limit: 10,
        page: 1,
        total: 1
      }
    },
    recent: {
      list: [],
      loading: false,
      fetched: false
    }
  }
}
export default {
  state: defaultState(),

  mutations: {
    [TYPES.TO_PAGE_REFERRAL](state, page) {
       state.referral.pagination.page = page;
    },
    [TYPES.FETCH_REFERRAL](state, list) {
      state.referral.list = list || [];
    },
    [TYPES.PAGINATION_REFERRAL](state, pagi) {
      state.referral.pagination = pagi;
    },
    [TYPES.TO_PAGE_REJECTED_SHOP](state, page) {
      state.rejectedShop.pagination.page = page;
   },
   [TYPES.FETCH_REJECTED_SHOP](state, list) {
     state.rejectedShop.list = list || [];
   },
   [TYPES.PAGINATION_REJECTED_SHOP](state, pagi) {
     state.rejectedShop.pagination = pagi;
   },
    [TYPES.TO_PAGE_ACTIVITY](state, page) {
      state.activity.pagination.page = page;
    },
    [TYPES.FETCH_ACTIVITY](state, list) {
      state.activity.list = list || [];
    },
    [TYPES.PAGINATION_ACTIVITY](state, pagi) {
      state.activity.pagination = pagi;
    },

    [TYPES.FETCH_RECENT](state, list) {
      state.recent.list = list || [];
    },


    [TYPES.RESET](state) {
      let initial = defaultState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
      console.log({
        message: TYPES.RESET,
        state
      })
    }
  },
  actions: {
    fetchReferrals(store, to) {
      let referral = store.state.referral;
      if (to) {
        store.commit(TYPES.TO_PAGE_REFERRAL, to);
      }
      let {limit, page} = referral.pagination;
      referral.loading = true;
      referralAPI.get(limit, page).then(res => {
        referral.loading = false;
        referral.fetched = true;
        let {pagination, refs} = res.data;
        store.commit(TYPES.FETCH_REFERRAL, refs);
        store.commit(TYPES.PAGINATION_REFERRAL, pagination);
      }).catch(err => {
        console.warn({err})
        referral.loading = false;
        store.commit("OpenError", {
          title: "ERROR",
          message: (err.response && err.response.data && err.response.data.message) || "Can't get referrals information."
        })
      })
    },
    fetchRejectedShop(store, to) {
      let rejectedShop = store.state.rejectedShop;
      if (to) {
        store.commit(TYPES.TO_PAGE_REJECTED_SHOP, to);
      }
      let {limit, page} = rejectedShop.pagination;
      rejectedShop.loading = true;
      rejectedShopAPI.get(limit, page).then(res => {
      console.log(res)
      rejectedShop.loading = false;
        rejectedShop.fetched = true;
        let {pagination, refs} = res.data;
        store.commit(TYPES.FETCH_REJECTED_SHOP, refs);
        store.commit(TYPES.PAGINATION_REJECTED_SHOP, pagination);
      }).catch(err => {
        console.warn({err})
        rejectedShop.loading = false;
        store.commit("OpenError", {
          title: "ERROR",
          message: (err.response && err.response.data && err.response.data.message) || "Can't get rejected stored information."
        })
      })
    },
    fetchActivities(store, to) {
      let activity = store.state.activity;
      let recent = store.state.recent;
      if (to) {
        store.commit(TYPES.TO_PAGE_ACTIVITY, to);
      }
      let {limit, page} = activity.pagination;
      activity.loading = true;
      if (to == 1)
        recent.loading = true;
        limit = 10;
      referralAPI.activity(limit, page).then(res => {
        activity.loading = false;
        activity.fetched = true;
        let {pagination, activities} = res.data;
        if (to == 1) {
          recent.loading = true;
          recent.fetched = true;
          store.commit(TYPES.FETCH_RECENT, activities);
        }
        store.commit(TYPES.FETCH_ACTIVITY, activities);
        store.commit(TYPES.PAGINATION_ACTIVITY, pagination);
      }).catch(err => {
        console.warn({err})
        activity.loading = false;
        store.commit("OpenError", {
          title: "ERROR",
          message: (err.response && err.response.data && err.response.data.message) || "Can't get referrals information."
        })
      })
    },
    fetchRecentActivities(store, to) {
      let activity = store.state.activity;
      let recent = store.state.recent;
      if (to) {
        store.commit(TYPES.TO_PAGE_ACTIVITY, to);
      }
      let {limit, page} = activity.pagination;
      activity.loading = true;
      if (to == 1)
        recent.loading = true;
      referralAPI.activity(100, page).then(res => {
        activity.loading = false;
        activity.fetched = true;
        let {pagination, activities} = res.data;
        if (to == 1) {
          recent.loading = true;
          recent.fetched = true;
          store.commit(TYPES.FETCH_RECENT, activities);
        }
        store.commit(TYPES.FETCH_ACTIVITY, activities);
        store.commit(TYPES.PAGINATION_ACTIVITY, pagination);
      }).catch(err => {
        console.warn({err})
        activity.loading = false;
        store.commit("OpenError", {
          title: "ERROR",
          message: (err.response && err.response.data && err.response.data.message) || "Can't get referrals information."
        })
      })
    }
  }
};
