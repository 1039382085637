import api from "./index";
import { catcher } from "./handleError";

export default {
  get(limit, page) {
    let ql = limit ? `limit=${limit}` : "";
    let qp = page ? `page=${page}` : "";
    let query = "?";
    if (ql) query += ql + "&";
    if (qp) query += qp + "&";
    query = query.substring(0, query.length - 1);
    return api().get(`/api/referrals${query}`)
  },
  history(shop, limit, page) {
    let ql = from ? `limit=${limit}` : "";
    let qp = to ? `page=${page}` : "";
    let query = "?";
    if (ql) query += ql + "&";
    if (qp) query += qp + "&";
    query = query.substring(0, query.length - 1);
    return api().get(`/api/referrals/${shop}${query}`)
  },

  activity(limit, page) {
    let ql = limit ? `limit=${limit}` : "";
    let qp = page ? `page=${page}` : "";
    let query = "?";
    if (ql) query += ql + "&";
    if (qp) query += qp + "&";
    query = query.substring(0, query.length - 1);
    return api().get(`/api/referrals/activities${query}`)
  },
};
